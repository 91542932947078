import React, { useState } from 'react'
import Header from '../Components/Header'
import { Outlet } from 'react-router-dom'
import Sidebar from '../Components/Sidebar'

const Wrapper = () => {

  const [displaySide,setDisplaySide]=useState(false)

  return (
    <div className=' mt-5'>
     
      <div  style={{zIndex:99}}>
        <Sidebar />
      </div>
      
      
      <div className='set-padding '>
      <div>
        <Header name={'Dashboard'} />
      </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Wrapper