import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useNavigate } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const AddClient = () => {


    const navigate = useNavigate()






    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [phone, setPhone] = useState(null)
    const [planData, setPlanData] = useState(null)
    const [planId, setPlanId] = useState(null)
    const [businessName, setBusinessName] = useState(null)
    const [clientAgent, setClientAgent] = useState(null)



    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/showPlans`).then(res => {
                if (res.data.status === 'ok') {
                    setPlanData(res.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])




    const submit = () => {
        if (!name) {
            toast('🚨 Must enter client name!')
        }
        else if (!email) {
            toast('🚨 Must enter client email!')
        }
        else if (!phone) {
            toast('🚨 Must enter client number!')
        }
        else if (!businessName) {
            toast('🚨 Must enter business name!')
        }
        else if (!clientAgent) {
            toast('🚨 Must select client agent!')
        }
        else if (!planId) {
            toast('🚨 Must select plan!')
        }
        else {

            try {
                const param = {
                    name: name,
                    email: email,
                    phone: phone,
                    business_name: businessName,
                    client_agent: clientAgent,
                    plan_id: planId,
                }


                axios.post(`${BaseUrl.baseUrl}/addClient`, param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Created Successfully!')
                        navigate(-1)
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div id="content" className="app-content ms-0">
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">Pages</Link>
                        </li>
                        <li className="breadcrumb-item active">Clients</li>
                    </ul>
                    <h1 className="page-header mb-0">Clients</h1>
                </div>
                <div className="ms-auto">
                    <Link to="/showClients" className="btn btn-theme fs-13px fw-semibold">
                        Show Client
                    </Link>
                </div>
            </div>
            <div className="row gx-4">
                <div className="col-xl-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="response-message" />
                            <div >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }}
                                    >
                                        <label htmlFor="">Client Name</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="name"
                                            placeholder="client name"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="mb-2 mt-3 col-5" style={{ marginRight: 40 }}>
                                        <label htmlFor="">Email</label> <br />
                                        <input
                                            type="email"
                                            name="email"
                                            placeholder="Client Email"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setEmail(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }}
                                    >
                                        <label htmlFor="">Client Number</label>
                                        <input
                                            type="number"
                                            name="number"
                                            placeholder="client number"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setPhone(e.target.value) }}
                                        />
                                    </div>
                                    <div className="mb-2 mt-3 col-5" style={{ marginRight: 40 }}>
                                        <label htmlFor="">Business Name</label> <br />
                                        <input
                                            type="text"
                                            name="business_name"
                                            placeholder="Client Business Name"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setBusinessName(e.target.value) }}
                                        />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }}
                                    >
                                        <label htmlFor="">Client Agent</label>
                                        <select
                                            name="client_agent"
                                            className="form-select border rounded-3"
                                            id=""
                                            required=""
                                            onChange={(e) => { setClientAgent(e.target.value) }}
                                        >
                                            <option value="">Select the Client Agent</option>
                                            <option value="Afaq Ahmed">Afaq Ahmed </option>
                                            <option value="Ari Akash">Ari Akash</option>
                                            <option value="Bilal Tariq">Bilal TAriq</option>
                                            <option value="Muhammad Hadi">Muhammad Hadi</option>
                                            <option value="Muhammad Musa">Muhammad Mosa</option>
                                        </select>
                                    </div>
                                    <div className="mb-2 mt-3 col-5" style={{ marginRight: 40 }}>
                                        <label htmlFor="">Package</label> <br />
                                        <select name="plan" className="form-select  border rounded-3" id="" required="" onClick={(e)=>{setPlanId(e.target.value)}}>
                                            <option value="">Select the Client Package</option>
                                            {planData?.plans?.map(i => {
                                                return (<>
                                                    <option value={i?.id} >
                                                        {i?.name}
                                                    </option>
                                                </>)
                                            })}

                                        </select>
                                    </div>
                                </div>
                                <div
                                    className="mb-2 mt-2"
                                    style={{ float: "inline-end", marginRight: 30 }}
                                >
                                    <button type="submit" className="btn btn-theme" onClick={submit}>
                                        Add Client
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddClient