import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useNavigate } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';


const LoadTemplate = () => {


    const navigate = useNavigate()



    const [name, setName] = useState(null)
    const [language, setLanguage] = useState(null)
    const [category, setCategory] = useState(null)


    const submit = () => {
        if (!name) {
            toast('🚨 Must enter template name!')
        }
        else if (!language) {
            toast('🚨 Must enter template language!')
        }
        else if (!category) {
            toast('🚨 Must select template type!')
        }
        else {

            console.log('clickkk');
            try {
                const param = {
                    name: name,
                    language: language,
                    category: category,
                }


                axios.post(`${BaseUrl.baseUrl}/addAdminTemplate`, param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Created Successfully!')
                        navigate(-1)
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div id="content" className="app-content ms-0">
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">Pages</Link>
                        </li>
                        <li className="breadcrumb-item active">Templates</li>
                    </ul>
                    <h1 className="page-header mb-0">Template</h1>
                </div>
                <div className="ms-auto">
                    <Link to="/showTemplates" className="btn btn-theme fs-13px fw-semibold">
                        Show Template
                    </Link>
                </div>
            </div>
            <div className="row gx-4">
                <div className="col-xl-12">
                    <div className="card mb-4">
                        <div className="card-body">
                           
                            <div className="response-message" />
                            <div >
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div
                                        className="mb-2 mt-3 col-3"
                                        style={{ marginLeft: 20, marginRight: 20 }}
                                    >
                                        <label htmlFor="">Template Name</label> <br />
                                        <br />
                                        <input
                                            type="text"
                                            name="template_name"
                                            placeholder="Template name"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setName(e.target.value) }}
                                        />
                                    </div>
                                    <div className="mb-2 mt-3 col-3" style={{ marginRight: 40 }}>
                                        <label htmlFor="">Template language</label> <br /> <br />
                                        <input
                                            type="text"
                                            name="template_lang"
                                            placeholder="Template language"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setLanguage(e.target.value) }}
                                        />
                                    </div>
                                    <div className="mb-2 mt-3 col-3" style={{}}>
                                        <label htmlFor="">Template type</label> <br /> <br />
                                        <select
                                            name="template_type"
                                            className="form-select border rounded-3"
                                            id=""
                                            required=""
                                            onChange={(e) => { setCategory(e.target.value) }}
                                        >
                                            <option value="" selected="" disabled="">
                                                Select the template type
                                            </option>
                                            <option value="account_created">
                                                Account Created Template{" "}
                                            </option>
                                            <option value="account_activated">
                                                Account Activated Template{" "}
                                            </option>
                                            <option value="expired">Expired Template </option>
                                            <option value="expired_soon">Expired Soon Template </option>
                                        </select>
                                    </div>
                                </div>
                                <div
                                    className="mb-2 mt-2"
                                    style={{ float: "inline-end", marginRight: 30 }}
                                >
                                    <button type="button" className="btn btn-theme" onClick={()=>{
                                        console.log('clivk');
                                        submit()
                                        }}>
                                        Add Template
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default LoadTemplate