import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import toast from "react-simple-toasts";

const Sidebar = () => {
  const location = useLocation();
  const isActive = (route) => location.pathname.includes(route);


  const [showSub,setShowSub]=useState(false)
  const [showPaySub,setShowPaySub]=useState(false)

  const navigate = useNavigate();



  useEffect(()=>{
    if(!localStorage.getItem('login')){
      toast('Please login!')
      navigate('/')
    }
  },[])

  return (
    <div id="sidebar" className="app-sidebar">
  {/* BEGIN scrollbar */}
  <div
    className="app-sidebar-content ps ps--active-y"
    data-scrollbar="true"
    data-height="100%"
    data-init="true"
    style={{ height: "100%",width:'269px' }}
  >
    {/* BEGIN menu */}
    <div className="menu " style={{overflowX:'hidden',overflowY:'scroll'}} >
      <div className="menu-profile">
        <Link
          to="javascript:;"
          className="menu-profile-link"
          data-bs-toggle="dropdown"
        >
          <div className="menu-profile-cover with-shadow" />
          <div className="menu-profile-image">
            <img
              className=""
              src={require('../Assests/logo.png')}
              style={{ width: 230, borderRadius: 0 }}
              alt=""
            />
          </div>
          <br />
          <br />
          <div className="menu-profile-info">
            <div className="d-flex align-items-center">
              <div className="flex-grow-1 fw-bold">Admin</div>
              {/* <div className="ms-auto">
                <i className="fa fa-chevron-down" />
              </div> */}
            </div>
            <small>admin@gmail.com</small>
          </div>
        </Link>
        <div className="dropdown-menu dropdown-menu-end me-lg-3 mt-1 w-200px">
          <Link
            className="dropdown-item d-flex align-items-center"
            to="/adminProfile"
          >
            <i className="far fa-user fa-fw fa-lg me-3" /> Profile
          </Link>
          <Link
            className="dropdown-item d-flex align-items-center"
            to="email_inbox.html"
          >
            <i className="far fa-envelope fa-fw fa-lg me-3" /> Inbox
          </Link>
          <Link
            className="dropdown-item d-flex align-items-center"
            to="calendar.html"
          >
            <i className="far fa-calendar fa-fw fa-lg me-3" /> Calendar
          </Link>
          <Link
            className="dropdown-item d-flex align-items-center"
            to="settings.html"
          >
            <i className="fa fa-sliders fa-fw fa-lg me-3" /> Settings
          </Link>
          <div className="dropdown-divider" />
          <Link className="dropdown-item d-flex align-items-center" to="/">
            <i className="fa fa-arrow-right-from-bracket fa-fw fa-lg me-3" />
            Logout
          </Link>
        </div>
      </div>
      <div className="menu-header">Navigation</div>
      <div className={`menu-item ${isActive("/dashboard")?'active':''}`}>
        <Link to="/dashboard" className="menu-link">
          <span className="menu-icon">
            <i className="fa fa-qrcode" />
          </span>
          <span className="menu-text">Dashboard</span>
        </Link>
      </div>
      {/* teacher */}
      <div className="menu-item has-sub">
        <div to="#" className="menu-link" style={{cursor:'pointer'}} onClick={()=>{setShowSub(!showSub)}}>
          <span className="menu-icon">
            <i className="fa fa-users" />
          </span>
          <span className="menu-text">Clients</span>
          <span className="menu-caret">
            <b className="caret" />
          </span>
        </div>
        {showSub?<div className="menu ">
          <div className={`menu-item ${isActive("/showClients")?'active':''}`}>
            <Link to="/showClients" className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">All Clients</span>
            </Link>
          </div>
          <div className={`menu-item ${isActive("/activeClients")?'active':''}`}>
            <Link to="/activeClients" className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">Active Clients</span>
            </Link>
          </div>
          <div className={`menu-item ${isActive("/expiredClients")?'active':''}`}>
            <Link to="/expiredClients" className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">Expired Clients</span>
            </Link>
          </div>
          <div className={`menu-item ${isActive("/expiredSoonClients")?'active':''}`}>
            <Link to="/expiredSoonClients" className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">Expired Soon Clients</span>
            </Link>
          </div>
        </div>:null}
      </div>


      {/* teacher */}
      <div className="menu-item has-sub">
        <div to="#" className="menu-link" style={{cursor:'pointer'}} onClick={()=>{setShowPaySub(!showPaySub)}}>
          <span className="menu-icon">
            <i className="fa fa-wallet" />
          </span>
          <span className="menu-text">Payments</span>
          <span className="menu-caret">
            <b className="caret" />
          </span>
        </div>
        {showPaySub?<div className="menu ">
          <div className={`menu-item ${isActive("/upcomingPayments")?'active':''}`}>
            <Link to="/upcomingPayments" className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">Upcoming payments</span>
            </Link>
          </div>
          <div className={`menu-item ${isActive("/transactionsInvoices")?'active':''}`}>
            <Link to="/transactionsInvoices" className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">Transactions Invoices</span>
            </Link>
          </div>
          <div className={`menu-item ${isActive("/quotations")?'active':''}`}>
            <Link to="/quotations " className="menu-link"  style={{paddingLeft:'3.5rem'}}>
              <span className="menu-text">Quotations</span>
            </Link>
          </div>
        </div>:null}
      </div>



      {/* plan */}
      <div className={`menu-item ${isActive("/showPlans")?'active':''}`}>
        <Link to="/showPlans" className="menu-link">
          <span className="menu-icon">
            <i className="fa fa-list" />
          </span>
          <span className="menu-text">Plans</span>
        </Link>
      </div>


      <div className={`menu-item ${isActive("/showSubscription")?'active':''}`}>
        <Link to="/showSubscription" className="menu-link">
          <span className="menu-icon">
            <i className="fa fa-suitcase" />
          </span>
          <span className="menu-text">Subscription</span>
        </Link>
      </div>


      <div className={`menu-item ${isActive("/notification")?'active':''}`}>
        <Link to="/notification" className="menu-link">
          <span className="menu-icon">
            <i className="fa fa-bell" />
          </span>
          <span className="menu-text">Notification</span>
        </Link>
      </div>



      <div className={`menu-item ${isActive("/showTemplates")?'active':''}`}>
        <Link to="/showTemplates" className="menu-link">
          <span className="menu-icon">
            <i className="fa fa-clipboard" />
          </span>
          <span className="menu-text">Template</span>
        </Link>
      </div>
    </div>
    {/* END menu */}
    <div className="p-3 mt-auto">
      <Link
        to="/"
        className="btn d-block btn-theme fs-13px fw-semibold rounded-pill"
      >
        <i className="fa fa-arrow-left opacity-5" /> Logout
      </Link>
    </div>
    <div className="ps__rail-x" style={{ left: 0, bottom: 0 }}>
      <div className="ps__thumb-x" tabIndex={0} style={{ left: 0, width: 0 }} />
    </div>
    <div className="ps__rail-y" style={{ top: 0, height: 248, right: 0 }}>
      <div
        className="ps__thumb-y"
        tabIndex={0}
        style={{ top: 0, height: 135 }}
      />
    </div>
  </div>
  {/* END scrollbar */}
</div>

  );
};

export default Sidebar;
