import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';

const EditClient = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { id } = location.state





  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [planData, setPlanData] = useState(null)
  const [planId, setPlanId] = useState(null)
  const [businessName, setBusinessName] = useState(null)
  const [clientAgent, setClientAgent] = useState(null)
  const [status, setStatus] = useState(null)



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/showPlans`).then(res => {
        if (res.data.status === 'ok') {
          setPlanData(res.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])






  const [countryName, setCountryName] = useState(null)



  const [data, setData] = useState({})


  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/editClient/${id}`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setData(res.data.client)
          setName(res.data?.client?.name)
          setEmail(res.data?.client?.email)
          setPhone(res.data?.client?.phone)
          setBusinessName(res.data?.client?.business_name)
          setClientAgent(res.data?.client?.client_agent)
          setPlanId(res.data?.client?.plan_id)
          setStatus(res.data?.client?.plan_status)


        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])



  const submit = () => {


    try {

      const param = {
        name: name,
        email: email,
        phone: phone,
        business_name: businessName,
        client_agent: clientAgent,
        plan_id: planId,
        plan_status: status,
      }

      axios.post(`${BaseUrl.baseUrl}/editClient/${id}`, param).then(res => {
        if (res.data.status === 'ok') {
          toast('🎉 Updated Successfully!')
          navigate(-1)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })


    } catch (err) {
      toast('🚨 Something went wrong!')
    }


  }



  return (
    <div id="content" className="app-content ms-0">
      <div className="d-flex align-items-center mb-3">
        <div>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="#">Pages</Link>
            </li>
            <li className="breadcrumb-item active">Clients</li>
          </ul>
          <h1 className="page-header mb-0">Clients</h1>
        </div>
        <div className="ms-auto">
          <Link to="/showClients" className="btn btn-theme fs-13px fw-semibold">
            Show Client
          </Link>
        </div>
      </div>
      <div className="row gx-4">
        <div className="col-xl-12">
          <div className="card mb-4">
            <div className="card-body">
              <div className="response-message" />
              <div >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div
                    className="mb-2 mt-3 col-5"
                    style={{ marginLeft: 20, marginRight: 20 }}
                  >
                    <label htmlFor="">Client Name</label>
                    <br />
                    <input
                      type="text"
                      name="name"
                      placeholder="client name"
                      className="form-control"
                      required=""
                      defaultValue={name}
                      onChange={(e) => { setName(e.target.value) }}
                    />
                  </div>
                  <div className="mb-2 mt-3 col-5" style={{ marginRight: 40 }}>
                    <label htmlFor="">Email</label> <br />
                    <input
                      type="email"
                      name="email"
                      placeholder="Client Email"
                      className="form-control"
                      required=""
                      defaultValue={email}
                      onChange={(e) => { setEmail(e.target.value) }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <div
                    className="mb-2 mt-3 col-5"
                    style={{ marginLeft: 20, marginRight: 20 }}
                  >
                    <label htmlFor="">Client Number</label>
                    <input
                      type="number"
                      name="number"
                      placeholder="client number"
                      className="form-control"
                      required=""
                      defaultValue={phone}
                      onChange={(e) => { setPhone(e.target.value) }}
                    />
                  </div>
                  <div className="mb-2 mt-3 col-5" style={{ marginRight: 40 }}>
                    <label htmlFor="">Business Name</label> <br />
                    <input
                      type="text"
                      name="business_name"
                      placeholder="Client Business Name"
                      className="form-control"
                      required=""
                      defaultValue={businessName}
                      onChange={(e) => { setBusinessName(e.target.value) }}
                    />
                  </div>
                </div>


                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >



                  <div
                    className="mb-2 mt-3 col-5"
                    style={{ marginLeft: 20, marginRight: 20 }}
                  >
                    <label htmlFor="">Client Agent</label>
                    <select
                      name="client_agent"
                      className="form-select border rounded-3"
                      id=""
                      required=""
                      onChange={(e) => { setClientAgent(e.target.value) }}
                    >
                      <option value="">Select the Client Agent</option>
                      <option value="Afaq Ahmed" selected={clientAgent === 'Afaq Ahmed' ? true : false} >Afaq Ahmed </option>
                      <option value="Ari Akash" selected={clientAgent === 'Ari Akash' ? true : false}>Ari Akash</option>
                      <option value="Bilal Tariq" selected={clientAgent === 'Bilal Tariq' ? true : false}>Bilal TAriq</option>
                      <option value="Muhammad Hadi" selected={clientAgent === 'Muhammad Hadi' ? true : false}>Muhammad Hadi</option>
                      <option value="Muhammad Musa" selected={clientAgent === 'Muhammad Musa' ? true : false}>Muhammad Mosa</option>
                    </select>
                  </div>



                  <div className="mb-2 mt-3 col-5" style={{ marginRight: 40 }}>
                    <label htmlFor="">Package</label> <br />
                    <select name="plan" className="form-select  border rounded-3" id="" required="" onClick={(e) => { setPlanId(e.target.value) }}>
                      <option value="">Select the Client Package</option>
                      {planData?.plans?.map(i => {
                        return (<>
                          <option value={i?.id} selected={planId === i?.id ? true : false} >
                            {i?.name}
                          </option>
                        </>)
                      })}

                    </select>
                  </div>





                </div>



                <div className="mb-2 mt-3 col-5" style={{ marginRight: 40, marginLeft: 20, }}>
                  <label htmlFor="">Status</label> <br />
                  <select name="plan" className="form-select  border rounded-3" id="" required="" onClick={(e) => { setStatus(e.target.value) }}>
                    <option value="">Select the Client Status</option>

                    <option value="Active" selected={status === 'Active' ? true : false}>Active</option>
                    <option value="Inactive" selected={status === 'Inactive' ? true : false}>Inactive</option>
                    <option value="Expired" selected={status === 'Expired' ? true : false}>Expired</option>

                  </select>
                </div>


                <div
                  className="mb-2 mt-2"
                  style={{ float: "inline-end", marginRight: 30 }}
                >
                  <button type="submit" className="btn btn-theme" onClick={submit}>
                    Update Client
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default EditClient