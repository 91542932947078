import logo from "./logo.svg";
import "./App.css";
import Chart from "chart.js/auto";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Wrapper from "./Pages/Wrapper";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import OpenMap from "./Components/OpenMap";
import MapContext from "./Components/MapContext";
import { useEffect, useState } from "react";
import { toastConfig } from "react-simple-toasts";
import "react-simple-toasts/dist/theme/dark.css"; // choose your theme
import EmailTemp from "./Pages/EmailTemp";
import BaseUrl from "./BaseUrl";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import ShowPlans from "./Pages/Plans/ShowPlans";
import AddPlan from "./Pages/Plans/AddPlan";
import EditPlan from "./Pages/Plans/EditPlan";
import ShowClients from "./Pages/Clients/ShowClients";
import AddClient from "./Pages/Clients/AddClient";
import ActiveClients from "./Pages/Clients/ActiveClients";
import ExpiredClients from "./Pages/Clients/ExpiredClients";
import ExpiredSoonClients from "./Pages/Clients/ExpiredSoonClients";
import ShowTemplates from "./Pages/Templates/ShowTemplates";
import EditClient from "./Pages/Clients/EditClient";
import LoadTemplate from "./Pages/Templates/LoadTemplate";
import Subscription from "./Pages/Subscription/Subscription";
import AddSubscription from "./Pages/Subscription/AddSubscription";
import EditSubscription from "./Pages/Subscription/EditSubscription";
import Notification from "./Pages/Notification/Notification";
import AddNotification from "./Pages/Notification/AddNotification";
import EditNotification from "./Pages/Notification/EditNotification";
import Quotation from "./Pages/Quotation/Quotation";
import AddQuotation from "./Pages/Quotation/AddQuotation";
import EditQuotation from "./Pages/Quotation/EditQuotation";
import ViewQuotation from "./Pages/Quotation/ViewQuotation";

function App() {
  toastConfig({ theme: "dark" });

  const [mapValue, setMapValue] = useState(null);



  return (
    <>
      <MapContext.Provider
        value={{ mapValue: mapValue, setMapValue: setMapValue }}
      >
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/email" element={<EmailTemp />} /> 
            <Route path="/viewQuotation" element={<ViewQuotation />} />
            <Route path="/openMap" element={<OpenMap />} />
            <Route element={<Wrapper />}>
              <Route path="/dashboard" element={<Home />} />
              



              <Route path="/showPlans" element={<ShowPlans />} />
              <Route path="/addPlan" element={<AddPlan />} />
              <Route path="/editPlan/:id" element={<EditPlan />} />



              <Route path="/quotations" element={<Quotation />} />
              <Route path="/addQuotation" element={<AddQuotation />} />
              <Route path="/editQuotation" element={<EditQuotation />} />


              <Route path="/notification" element={<Notification />} />
              <Route path="/addNotification" element={<AddNotification />} />
              <Route path="/editNotification" element={<EditNotification />} />


              
              <Route path="/showSubscription" element={<Subscription />} />
              <Route path="/addSubscription" element={<AddSubscription />} />
              <Route path="/editSubscription/:id" element={<EditSubscription />} />
              
              
              <Route path="/showTemplates" element={<ShowTemplates />} />
              <Route path="/loadTemplate" element={<LoadTemplate />} />
              
              
              <Route path="/showClients" element={<ShowClients />} />
              <Route path="/activeClients" element={<ActiveClients />} />
              <Route path="/expiredClients" element={<ExpiredClients />} />
              <Route path="/expiredSoonClients" element={<ExpiredSoonClients />} />
              <Route path="/addClient" element={<AddClient />} />
              <Route path="/editClient" element={<EditClient />} />
              
             
            </Route>
          </Routes>
        </BrowserRouter>
      </MapContext.Provider>
    </>
  );
}

export default App;
