import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../../BaseUrl'
import toast from 'react-simple-toasts'
import Swal from 'sweetalert2'
import moment from 'moment'

const Quotation = () => {

    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [mysearch, setSearch] = useState('')


    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }


    const query = useQuery();
    const page = query.get('page');
    const search = query.get('search');




    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/quotation/get?page=${page?page:1}&search=${search?search:''}`).then(res => {
                if (res.data.status === 'ok') {

                    console.log(res.data);
                    setData(res.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])




    const onsubmitsearch=(spage,ssearch)=>{
        try {

            axios.get(`${BaseUrl.baseUrl}/quotation/get?page=${spage}&search=${ssearch}`).then(res => {
                if (res.data.status === 'ok') {

                    console.log(res.data);
                    setData(res.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }
    }






    const del = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {

            if (result.isConfirmed) {

                try {

                    axios.delete(`${BaseUrl.baseUrl}/quotation/delete/${id}`).then(res => {
                        if (res.data.status === 'ok') {
                            toast('🎉 Deleted Successfully!')

                            axios.get(`${BaseUrl.baseUrl}/quotation/get?page=${page?page:1}&search=${search?search:''}`).then(res => {
                                if (res.data.status === 'ok') {
                                    setData(res.data)
                                }
                                else if (res.data.status === 'fail') {
                                    toast('🚩' + res.data.message)
                                }

                            }).catch(err => {
                                toast('🚨' + err.message)
                            })

                        }
                        else if (res.data.status === 'fail') {
                            toast('🚩' + res.data.message)
                        }

                    }).catch(err => {
                        toast('🚨' + err.message)
                    })


                } catch (err) {
                    toast('🚨 Something went wrong!')
                }
            }


        })
    }



    return (
        <div id="content" className="app-content  ms-0">
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">Pages</Link>
                        </li>
                        <li className="breadcrumb-item active">Quotation</li>
                    </ul>
                    <h1 className="page-header mb-0">Quotation</h1>
                </div>
                <div className="ms-auto">
                    <Link to="/addQuotation" className="btn btn-theme fs-13px fw-semibold ">
                        Add Quotation
                    </Link>
                </div>
            </div>
            <div id="alertContainer" className="col-md-4" />
            <div className="card">
                <ul className="nav nav-tabs nav-tabs-v2 px-3">
                    <li className="nav-item me-sm-2">
                        <Link to="#allTab" className="nav-link active px-2" data-bs-toggle="tab">
                            All
                        </Link>
                    </li>
                </ul>
                <div className="tab-content p-4">
                    <div className="tab-pane fade show active" id="allTab">
                        {/* BEGIN input-group */}
                        <div className="input-group flex-nowrap mb-4">
                            <div className="flex-fill position-relative">
                                <form action="">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            className="form-control px-35px"
                                            placeholder="Filter Plan"
                                            onChange={(e) => { setSearch(e.target.value) }}
                                        />
                                        <div
                                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                                            style={{ zIndex: 1020 }}
                                        >
                                            <i className="fa fa-search opacity-5" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <button className="btn btn-theme" type="submit" onClick={() => {
                                navigate(`?page=${page}&search=${mysearch}`)
                                onsubmitsearch(page, mysearch)
                            }}>
                                <span className="d-none d-md-inline  btn-theme">Submit</span>
                                <span className="d-inline d-md-none border btn-theme">
                                    <i className="fa fa-check" />
                                </span>
                                &nbsp;
                            </button>
                        </div>
                        {/* END input-group */}
                        {/* BEGIN table */}
                        <div className="table-responsive">
                            
                            <table id="orderTable" className="table table-hover text-nowrap">
                                <thead className="text-center">
                                    <tr>
                                        <th className="border-top-0 pt-0 pb-2">Sr no</th>
                                        <th className="border-top-0 pt-0 pb-2">Company Name</th>
                                        <th className="border-top-0 pt-0 pb-2">Company Email</th>
                                        <th className="border-top-0 pt-0 pb-2">Company Phone</th>
                                        <th className="border-top-0 pt-0 pb-2">Customer Name</th>
                                        <th className="border-top-0 pt-0 pb-2">Customer Email</th>
                                        <th className="border-top-0 pt-0 pb-2">Customer Phone</th>
                                        <th className="border-top-0 pt-0 pb-2">Total Bill</th>
                                        <th className="border-top-0 pt-0 pb-2">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {data?.data?.map((i, index) => {
                                        return (
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{i.companyName}</td>
                                                <td>{i.companyEmail}</td>
                                                <td>{i.companyPhone}</td>
                                                <td>{i.custName}</td>
                                                <td>{i.custEmail}</td>
                                                <td>{i.custPhone}</td>
                                                <td>{i.total}</td>
                                                <td>
                                                    &nbsp; &nbsp;{" "}
                                                    {/* Your button with orderId as a data attribute */}
                                                    &nbsp; &nbsp;
                                                    <div
                                                    onClick={()=>{
                                                        navigate(`/viewQuotation`,{state:{id:i.id}})
                                                    }}
                                                    className="fs-13px fw-semibold edit-category btn btn-theme"
                                                >
                                                    View
                                                </div>
                                                    &nbsp; &nbsp;
                                                    <div
                                                    onClick={()=>{
                                                        navigate(`/editQuotation`,{state:{id:i.id}})
                                                    }}
                                                    className="fs-13px fw-semibold edit-category btn btn-theme"
                                                >
                                                    Edit 
                                                </div>
                                                    &nbsp; &nbsp;
                                                    <div
                                                    onClick={() => { del(i?.id) }}
                                                    className="btn btn-danger"
                                                >
                                                    Delete{" "}
                                                </div>
                                                </td>
                                            </tr>)
                                    })}
                                </tbody>
                            </table>
                        </div>
                        {/* END table */}
                        {/* edit model start */}
                        <div
                            className="modal fade"
                            id="editModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Edit Plan
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div id="formResponse" />
                                        <form action="/updatePlan" id="createUserForm" method="post">
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Plan Name:
                                                </label>
                                                <input
                                                    type="text"
                                                    name="planName"
                                                    className="form-control"
                                                    id="edit-plan-name"
                                                    placeholder="Plan Name"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Plan Price:
                                                </label>
                                                <input
                                                    type="text"
                                                    name="price"
                                                    className="form-control"
                                                    id="edit-plan-price"
                                                    placeholder="Plan Price"
                                                />
                                            </div>
                                            <div className="first d-flex justify-content-between"></div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="submit" className="btn btn-primary">
                                            Update Setting
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* edit model end */}
                        {/* confirm delete  */}
                        <div id="custom-modal" className="overlay">
                            <div className="modal">
                                <span className="close-btn" onclick="closeModal()">
                                    ×
                                </span>
                                <p>Are you sure you want to delete this?</p>
                                <button onclick="confirmDelete()">Yes, delete</button>
                            </div>
                        </div>
                        {/* confirm delete  */}
                        <div className="d-lg-flex align-items-center">
                            <div className="me-lg-auto text-md-left text-center mt-2 mt-lg-0 mb-2 mb-lg-0">
                                Showing {data?.page} to {data?.limit} of {data?.count} entries
                            </div>
                            <ul className="pagination mb-0 justify-content-center flex-wrap">
                                {data?.page > 1 ? (
                                    <li className="page-item mb-sm-0 mb-1">
                                        <div className="page-link" style={{ cursor: 'pointer' }} onClick={() => {
                                            navigate(`?page=${data?.page - 1}&search=${search?search:''}`)
                                            onsubmitsearch(data?.page - 1, search?search:'')
                                        }
                                        }>
                                            Previous
                                        </div>
                                    </li>
                                ) : (
                                    <li className="page-item mb-sm-0 mb-1 disabled">
                                        <Link className="page-link">Previous</Link>
                                    </li>
                                )}

                                {[...Array(data?.totalPages).keys()].map(i => (
                                    <li key={i + 1} className={`page-item mb-sm-0 mb-1 ${i + 1 === data?.page ? 'active' : ''}`}>
                                        <div className="page-link" style={{ cursor: 'pointer' }} onClick={() => {
                                            navigate(`?page=${i + 1}&search=${search?search:''}`)
                                            onsubmitsearch(i + 1, search?search:'')
                                        }
                                        }>
                                            {i + 1}
                                        </div>
                                    </li>
                                ))}

                                {data?.page < data?.totalPages ? (
                                    <li className="page-item mb-sm-0 mb-1">
                                        <div className="page-link" style={{ cursor: 'pointer' }} onClick={() => {
                                            navigate(`?page=${data?.page + 1}&search=${search?search:''}`)
                                            onsubmitsearch(data?.page + 1, search?search:'')
                                        }}>
                                            Next
                                        </div>
                                    </li>
                                ) : (
                                    <li className="page-item mb-sm-0 mb-1 disabled">
                                        <Link className="page-link">Next</Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Quotation