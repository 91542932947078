import React, { useEffect, useState } from 'react'
import Header from '../Components/Header'
import { Bar, Line } from "react-chartjs-2";
import BaseUrl from '../BaseUrl';
import toast from 'react-simple-toasts';
import axios from 'axios';
import Chart from 'react-apexcharts'
import { Link } from 'react-router-dom';

const Home = () => {






  const [data, setData] = useState([])



  useEffect(() => {
    try {

      axios.get(`${BaseUrl.baseUrl}/dashboard`).then(res => {
        if (res.data.status === 'ok') {
          console.log(res.data);
          setData(res.data)
        }
        else if (res.data.status === 'fail') {
          toast('🚩' + res.data.message)
        }

      }).catch(err => {
        toast('🚨' + err.message)
      })



    } catch (err) {
      toast('🚨 Something went wrong!')
    }

  }, [])





  return (
    <div>


      <div id="content" className="app-content ms-0">
        <div className="d-lg-flex align-items-end mb-4">
          <h3 className="page-header mb-lg-0">
            Dashboard
            <small className="d-block fs-12px mt-1">
              Monitor key performance metrics for online store
            </small>
          </h3>
          <div className="ms-auto">
            <a
              href="#"
              className="btn fw-semibold fs-13px btn-secondary me-2"
              id="daterange-filter"
            >
              <i className="fa fa-calendar fa-fw me-md-1 opacity-5" />{" "}
              <span>17 Jun 2024 - 24 Jun 2024</span> <b className="caret ms-3" />
            </a>
            <Link to="#" className="btn fw-semibold fs-13px btn-theme">
              <i className="fa fa-upload fa-fw me-md-1 opacity-5" />{" "}
              <span className="d-none d-sm-inline">Export report</span>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-lg-6">
            {/* BEGIN card */}
            <div
              className="card bg-size-cover mb-3 border-dark"
              data-bs-theme="dark"
              style={{ backgroundImage: "url(assets/img/bg/pattern.jpg)" }}
            >
              {/* BEGIN card-body */}
              <div className="card-body">
                <div className="mb-3 fw-semibold d-flex align-items-center">
                  <div className="flex-fill">Total Clients</div>
                  <div>
                    <Link to="#" data-bs-toggle="dropdown" className="text-body">
                      <i className="bi bi-three-dots-vertical" />
                    </Link>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      data-bs-theme="light"
                    >
                      <Link to="#" className="dropdown-item">
                        View full report
                      </Link>
                      <Link to="#" className="dropdown-item">
                        Reload
                      </Link>
                      <Link to="#" className="dropdown-item">
                        Export Data
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-lg-12 position-relative">
                    <h3 className="mb-0">{data?.totalClient}</h3>
                  </div>
                </div>
                <div className="mt-3 fs-11px">
                  <div>last updated on</div>
                  <div className="fw-600">Feb 3, 4:09:57 AM UTC</div>
                </div>
              </div>
              {/* END card-body */}
            </div>
            {/* END card */}
          </div>
          <div className="col-xl-3 col-lg-6">
            {/* BEGIN card */}
            <div className="card mb-3">
              {/* BEGIN card-body */}
              <div className="card-body">
                <div className="mb-3 fw-semibold d-flex align-items-center">
                  <div className="flex-fill">Active Clients</div>
                  <div>
                    <Link to="#" data-bs-toggle="dropdown" className="text-body">
                      <i className="bi bi-three-dots-vertical" />
                    </Link>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      data-bs-theme="light"
                    >
                      <Link to="/activeClients" className="dropdown-item">
                        View full report
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-lg-12 position-relative">
                    <h3 className="mb-0 d-flex">{data?.activeClient}</h3>
                  </div>
                </div>
                <div className="mt-3 fs-11px">
                  <div className="text-body text-opacity-50">last updated on</div>
                  <div className="fw-600 text-body text-opacity-75">
                    Feb 3, 4:09:57 AM UTC
                  </div>
                </div>
              </div>
              {/* END card-body */}
            </div>
            {/* END card */}
          </div>
          <div className="col-xl-3 col-lg-6">
            {/* BEGIN card */}
            <div className="card mb-3">
              {/* BEGIN card-body */}
              <div className="card-body">
                <div className="mb-3 fw-semibold d-flex align-items-center">
                  <div className="flex-fill">Expired Client</div>
                  <div>
                    <Link to="#" data-bs-toggle="dropdown" className="text-body">
                      <i className="bi bi-three-dots-vertical" />
                    </Link>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      data-bs-theme="light"
                    >
                      <Link to="/expiredClients" className="dropdown-item">
                        View full report
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-lg-12 position-relative">
                    <h3> {data?.expiredClient}</h3>
                  </div>
                </div>
                <div className="mt-3 fs-11px">
                  <div className="text-body text-opacity-100 h6">
                    Expired Message to client
                  </div>
                  <div className="fw-600 text-body text-opacity-75">{data?.countSendExpiredMessage}</div>
                </div>
              </div>
              {/* END card-body */}
            </div>
            {/* END card */}
          </div>
          <div className="col-xl-3 col-lg-6">
            {/* BEGIN card */}
            <div className="card mb-3">
              {/* BEGIN card-body */}
              <div className="card-body">
                <div className="mb-3 fw-semibold d-flex align-items-center">
                  <div className="flex-fill">Expired Soon Clients</div>
                  <div>
                    <Link to="#" data-bs-toggle="dropdown" className="text-body">
                      <i className="bi bi-three-dots-vertical" />
                    </Link>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      data-bs-theme="light"
                    >
                      <Link to="/expiredSoonClients" className="dropdown-item">
                        View full report
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="row mb-1">
                  <div className="col-lg-12 position-relative">
                    <h3> {data?.expiredSoonClient} </h3>
                  </div>
                </div>
                <div className="mt-3 fs-11px">
                  <div className="text-body text-opacity-100 h6">
                    Expired Soon Message to client
                  </div>
                  <div className="fw-600 text-body text-opacity-75">{data?.countSendExpiredSoonMessage}</div>
                </div>
              </div>
              {/* END card-body */}
            </div>
            {/* END card */}
          </div>
          <div className="col-xl-12">
            {/* BEGIN card */}
            <div className="card mb-3">
              {/* BEGIN card-body */}
              <div className="card-body">
                <div className="mb-3 fw-semibold d-flex align-items-center">
                  <div className="flex-fill">Total Expired Clients</div>
                  <div className="button" style={{ float: "inline-end" }}>
                    <Link to="/expiredClients" className="btn btn-theme">
                      Details{" "}
                    </Link>
                  </div>
                </div>
                <div className="table-responsive">
                  <table id="orderTable" className="table table-hover text-nowrap">
                    <thead className="text-center">
                      <tr>
                        <th className="border-top-0 pt-0 pb-2">Sr no</th>
                        <th className="border-top-0 pt-0 pb-2">Name</th>
                        <th className="border-top-0 pt-0 pb-2">Number</th>
                        <th className="border-top-0 pt-0 pb-2">Agent</th>
                        <th className="border-top-0 pt-0 pb-2">Plan</th>
                        <th className="border-top-0 pt-0 pb-2">Business name</th>
                        <th className="border-top-0 pt-0 pb-2">Status</th>
                        <th className="border-top-0 pt-0 pb-2">Date</th>
                        <th className="border-top-0 pt-0 pb-2">
                          Expired Soon Message{" "}
                        </th>
                        <th className="border-top-0 pt-0 pb-2">Expired Message</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {data?.todayExpiredClients?.map((client, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{client.name}</td>

                            <td>{client.phone ? client.phone : '-'}</td>
                            <td>{client.client_agent ? client.client_agent : '-'}</td>
                            <td>{client?.plan?.name ? client?.plan?.name : '-'}</td>
                            <td>{client.business_name ? client.business_name : '-'}</td>

                            {client.plan_status === "Active" ? <td className="py-1 align-middle">
                              <span className="badge text-info bg-info bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                Active
                              </span>
                            </td> : client.plan_status === "Expired" ?
                              <td className="py-1 align-middle">
                                <span className="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                  <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                  {client.plan_status}
                                </span>
                              </td> : client.plan_status === "Expired_soon" ?
                                <td className="py-1 align-middle">
                                  <span className="badge text-warning bg-warninig bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                    <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                    {client.plan_status}
                                  </span>
                                </td> :
                                <td className="py-1 align-middle">
                                  <span className="badge text-info bg-info bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                    <i className="fa fa-circle fs-9px fa-fw me-5px" /> Inactive
                                  </span>
                                </td>}



                            <td>{client.formattedDate}</td>

                            {client.soonExpiredMessage === 1 ? <td class="py-1 align-middle"><span
                              class="badge text-success bg-success bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center"><i
                                class="fa fa-circle fs-9px fa-fw me-5px"></i>Sent</span></td>
                              :
                              <td class="py-1 align-middle"><span
                                class="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center"><i
                                  class="fa fa-circle fs-9px fa-fw me-5px"></i>Not Sent</span></td>}

                            {client.expiredMessage === 1 ? <td class="py-1 align-middle"><span
                              class="badge text-success bg-success bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center"><i
                                class="fa fa-circle fs-9px fa-fw me-5px"></i>Sent</span></td>
                              :
                              <td class="py-1 align-middle"><span
                                class="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center"><i
                                  class="fa fa-circle fs-9px fa-fw me-5px"></i>Not Sent</span></td>}





                          </tr>)
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* END card-body */}
            </div>
            {/* END card */}
          </div>
        </div>
      </div>



    </div>
  )
}

export default Home