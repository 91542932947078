import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useNavigate } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const AddPlan = () => {


    const navigate = useNavigate()






    const [name, setName] = useState(null)
    const [price, setPrice] = useState(null)

    const submit = () => {
        if (!name) {
            toast('🚨 Must enter package name!')
        }
        else if (!price) {
            toast('🚨 Must enter package price!')
        }
        else {

            try {
                const param = {
                    name: name,
                    price: price,
                }


                axios.post(`${BaseUrl.baseUrl}/addPlan`, param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Created Successfully!')
                        navigate(-1)
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div id="content" className="app-content ms-0">
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">Pages</Link>
                        </li>
                        <li className="breadcrumb-item active">Plan</li>
                    </ul>
                    <h1 className="page-header mb-0">Plan</h1>
                </div>
                <div className="ms-auto">
                    <Link to="/showPlans" className="btn btn-theme fs-13px fw-semibold ">
                        Show Plan
                    </Link>
                </div>
            </div>
            <div className="row gx-4">
                <div className="col-xl-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="response-message" />
                            <div >
                                <div className="mb-2 mt-3">
                                    <label htmlFor="">Plan Name</label>
                                    <br />
                                    <input
                                        type="text"
                                        name="planName"
                                        placeholder="plan name"
                                        className="form-control"
                                        required=""
                                        onChange={(e) => { setName(e.target.value) }}
                                    />
                                </div>
                                <div className="mb-2 mt-3">
                                    <label htmlFor="">Plan Price</label> <br />
                                    <input
                                        type="text"
                                        name="price"
                                        placeholder="plan price"
                                        className="form-control"
                                        required=""
                                        onChange={(e) => { setPrice(e.target.value) }}
                                    />
                                </div>
                                <div className="mb-2 mt-2" style={{ float: "inline-end" }}>
                                    <button type="submit" className="btn btn-theme" onClick={submit}>
                                        Add Plan
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddPlan