import React, { useState } from 'react'
import './Login.css'
import { useNavigate } from 'react-router-dom'
import CarouselSlider from '../Components/CarouselSlider'
import toast from 'react-simple-toasts'
import axios from 'axios'
import BaseUrl from '../BaseUrl'

const Login = () => {

    const navigate = useNavigate()
    const [activeTab, setActiveTab] = useState('Admin')
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)


    const submit = () => {
        try {

            if (!email) {
                toast('🚨 Must enter email!')
            }
            else if (!password) {
                toast('🚨 Must enter password!')
            }
            else {

                if (email === 'admin@gmail.com') {
                    if (password === 'admin') {
                        localStorage.setItem('login', "Admin")
                        toast('🎉 Login Successfully!')
                        navigate('/dashboard')
                    } else {
                        toast('🚨' + 'Invalid password')
                    }

                } else {
                    toast('🚨' + 'Invalid email')
                }

            }
        } catch (err) {
            toast('🚨 Something went wrong!')
        }
    }



    return (
        <div className=''>
            <div id="" className='d-flex align-items-center justify-content-center vh-100'>
                
                <div className='d-flex flex-column gap-2 '>
                <h1 class="text-center">Sign In</h1>
                <div class="text-body text-opacity-50 text-center mb-4">
						For your protection, please verify your identity.
					</div>

                    <div class="mb-3">
						<label className="form-label">Email Address <span className="text-danger">*</span></label>
						<input type="text" class="form-control form-control-lg fs-body" name="email" placeholder="" onChange={(e) => { setEmail(e.target.value) }}/>
					</div>


                    <div class="mb-3">
						<div class="d-flex">
							<label class="form-label">Password <span class="text-danger">*</span></label>
							{/* <a href="/forgetpassword" className="ms-auto text-body text-decoration-none text-opacity-50">Forgot password?</a> */}
						</div>
						<input type="password" name="password" className="form-control form-control-lg"  placeholder=""  onChange={(e) => { setPassword(e.target.value) }}/>
					</div>


                    <div class="mb-3">
						<div class="form-check">
							<input class="form-check-input rounded-1" type="checkbox" value="" id="customCheck1"/>
							<label class="form-check-label" for="customCheck1">Remember me</label>
						</div>
					</div>
                    

                    <button type="submit" onClick={() => { submit() }} className="btn btn-theme btn-lg d-block w-100 fw-semibold mb-3">Sign In</button>


                </div>
            </div>
        </div>
    )
}

export default Login