import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useNavigate } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import Swal from 'sweetalert2';
import "react-country-state-city/dist/react-country-state-city.css";

const AddQuotation = () => {


    const navigate = useNavigate()






    const [companyName, setCompanyName] = useState(null)
    const [companyEmail, setCompanyEmail] = useState(null)
    const [companyPhone, setCompanyPhone] = useState(null)
    const [companyAddress, setCompanyAddress] = useState(null)
    const [custName, setCustName] = useState(null)
    const [custEmail, setCustEmail] = useState(null)
    const [custPhone, setCustPhone] = useState(null)
    const [custAddress, setCustAddress] = useState(null)
    const [subTotal, setSubTotal] = useState(null)
    const [status, setStatus] = useState(null)
    const [dueDate, setDueDate] = useState(null)
    const [total, setTotal] = useState(null)
    const [tax, setTax] = useState(null)
    const [prodDesc, setProdDesc] = useState(null)
    const [prodQuant, setProdQuant] = useState(null)
    const [prodPrice, setProdPrice] = useState(null)
    const [product, setProduct] = useState([])

    const submit = () => {
        if (!companyName) {
            toast('🚨 Must enter company name!')
        }
        else if (!companyEmail) {
            toast('🚨 Must enter company email!')
        }
        else {

            try {
                const param = {
                    companyName: companyName,
                    companyEmail: companyEmail,
                    companyPhone: companyPhone,
                    companyAddress: companyAddress,
                    custName: custName,
                    custEmail: custEmail,
                    custPhone: custPhone,
                    custAddress: custAddress,
                    subTotal: product.reduce((accumulator, product) => accumulator + parseInt(product.price), 0),
                    total: (parseInt(product.reduce((accumulator, product) => accumulator + parseInt(product.price), 0))+parseInt(tax)),
                    tax: tax,
                    dueDate: dueDate,
                    status: status,
                    product: product,
                }


                axios.post(`${BaseUrl.baseUrl}/quotation/create`, param).then(res => {
                    if (res.data.status === 'ok') {
                        toast('🎉 Created Successfully!')
                        navigate(-1)
                    }
                    else if (res.data.status === 'fail') {
                        toast('🚩' + res.data.message)
                    }

                }).catch(err => {
                    toast('🚨' + err.message)
                })


            } catch (err) {
                toast('🚨 Something went wrong!')
            }

        }

    }



    return (
        <div id="content" className="app-content ms-0">
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">Pages</Link>
                        </li>
                        <li className="breadcrumb-item active">Quotation</li>
                    </ul>
                    <h1 className="page-header mb-0">Quotation</h1>
                </div>
                <div className="ms-auto">
                    <Link to="/quotations" className="btn btn-theme fs-13px fw-semibold ">
                        Show Quotation
                    </Link>
                </div>
            </div>
            <div className="row gx-4">
                <div className="col-xl-12">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="response-message" />
                            <div >

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Company Name</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="companyName"
                                            placeholder="Company Name"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCompanyName(e.target.value) }}
                                        />
                                    </div>


                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Company Email</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="companyName"
                                            placeholder="Company Email"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCompanyEmail(e.target.value) }}
                                        />
                                    </div>

                                </div>


                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Company Phone</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="companyPhone"
                                            placeholder="Company Phone"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCompanyPhone(e.target.value) }}
                                        />
                                    </div>


                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Company Address</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="companyAddress"
                                            placeholder="Company Address"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCompanyAddress(e.target.value) }}
                                        />
                                    </div>

                                </div>




                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Customer Name</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="customerName"
                                            placeholder="Customer Name"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCustName(e.target.value) }}
                                        />
                                    </div>


                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Customer Email</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="customerEmail"
                                            placeholder="Customer Email"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCustEmail(e.target.value) }}
                                        />
                                    </div>

                                </div>


                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Customer Phone</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="customerPhone"
                                            placeholder="Customer Phone"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCustPhone(e.target.value) }}
                                        />
                                    </div>


                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Customer Address</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="customerAddress"
                                            placeholder="Customer Address"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setCustAddress(e.target.value) }}
                                        />
                                    </div>

                                </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >

                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Due Date</label>
                                        <br />
                                        <input
                                            type="date"
                                            name="dueDate"
                                            placeholder="Due Date"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setDueDate(e.target.value) }}
                                        />
                                    </div>



                                    <div className="mb-2 mt-3 col-5"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Tax</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="tax"
                                            placeholder="Tax"
                                            className="form-control"
                                            required=""
                                            onChange={(e) => { setTax(e.target.value) }}
                                        />
                                    </div>

                                </div>












                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div className="mb-2 mt-3 col-5" style={{ marginRight: 40, marginLeft: 20, }}>
                                        <label htmlFor="">Status</label> <br />
                                        <select name="plan" className="form-select  border rounded-3" id="" required="" onClick={(e) => { setStatus(e.target.value) }}>
                                            <option value="">Select the Quotation Status</option>

                                            <option value="Paid">Paid</option>
                                            <option value="Unpaid">Unpaid</option>

                                        </select>
                                    </div>



                                </div>







                                <p className='mt-4 fw-bold'>Add Products</p>



                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center"
                                    }}
                                >
                                    <div className="mb-2 mt-3 col-3"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Product Name</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="productDescription"
                                            placeholder="Product Name"
                                            className="form-control"
                                            required=""
                                            defaultValue={prodDesc}
                                            onChange={(e) => { setProdDesc(e.target.value) }}
                                        />
                                    </div>


                                    <div className="mb-2 mt-3 col-3"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Product Quantity</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="productQuantity"
                                            placeholder="Product Quantity"
                                            className="form-control"
                                            required=""
                                            defaultValue={prodQuant}
                                            onChange={(e) => { setProdQuant(e.target.value) }}
                                        />
                                    </div>

                                    <div className="mb-2 mt-3 col-3"
                                        style={{ marginLeft: 20, marginRight: 20 }} >
                                        <label htmlFor="">Product Price</label>
                                        <br />
                                        <input
                                            type="text"
                                            name="productQuantity"
                                            placeholder="Product Price"
                                            className="form-control"
                                            required=""
                                            defaultValue={prodPrice}
                                            onChange={(e) => { setProdPrice(e.target.value) }}
                                        />
                                    </div>



                                </div>



                                <div className=" mb-5 mt-0" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <button type="submit" className="btn btn-theme" onClick={() => {
                                        console.log(prodDesc, prodQuant, prodPrice);
                                        if (prodDesc === null && prodPrice === null && prodQuant === null) {
                                            toast('Must add all product fields')
                                        }
                                        else {

                                            setProduct([...product, {
                                                name: prodDesc,
                                                quant: prodQuant,
                                                price: prodPrice
                                            }])


                                            setProdDesc('')
                                            setProdQuant('')
                                            setProdPrice('')
                                        }


                                    }}>
                                        Add
                                    </button>
                                </div>








                                <div
                                    className="table-responsive pb-5"
                                    style={{ overflow: "hidden", outline: "none" }}
                                    tabIndex={0}
                                >
                                    <table className="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th className="per70 text-center">Description</th>
                                                <th className="per5 text-center">Qty</th>
                                                <th className="per25 text-center">Total</th>
                                                <th className="per25 text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.map(i => {
                                                return (<>
                                                    <tr>
                                                        <td className='p-3' >
                                                            {i?.name}
                                                        </td>
                                                        <td className="text-center p-3">{i?.quant}</td>
                                                        <td className="text-center p-3" style={{ padding: '1.5rem' }}>${i?.price} USD</td>
                                                        <td className="text-center p-3">
                                                            <div
                                                                onClick={() => { setProduct(product?.filter(item => item !== i)) }}
                                                                className="btn btn-danger"
                                                            >
                                                                Delete{" "}
                                                            </div>
                                                        </td>
                                                    </tr>

                                                </>)
                                            })}

                                        </tbody>

                                    </table>
                                </div>



                                <div className="mb-2 mt-2" style={{ float: "inline-end" }}>
                                    <button type="submit" className="btn btn-theme" onClick={submit}>
                                        Add Quotation
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AddQuotation