import React from "react";

const Header = ({ name }) => {


  // console.log();

  return (
    <div id="header" className="app-header">
  {/* BEGIN desktop-toggler */}
  <div className="desktop-toggler">
    <button
      type="button"
      className="menu-toggler"
      data-toggle-class="app-sidebar-collapsed"
      data-toggle-target=".app"
    >
      <span className="bar" />
      <span className="bar" />
    </button>
  </div>
  {/* END desktop-toggler */}
  {/* BEGIN mobile-toggler */}
  <div className="mobile-toggler">
    <button
      type="button"
      className="menu-toggler"
      data-toggle-class="app-sidebar-mobile-toggled"
      data-toggle-target=".app"
    >
      <span className="bar" />
      <span className="bar" />
    </button>
  </div>
  {/* END mobile-toggler */}
  {/* BEGIN brand */}
  <div className="brand">
    <a href="#" className="brand-logo">
      Oncloud Renewal
    </a>
  </div>
  {/* END brand */}
  {/* BEGIN menu */}
  <div className="menu">
    <div className="menu-item dropdown">
      <a
        href="#"
        data-toggle-class="app-header-menu-search-toggled"
        data-toggle-target=".app"
        className="menu-link"
      >
        <div className="menu-icon">
          <i className="bi bi-search nav-icon" />
        </div>
      </a>
    </div>
    {/* <div className="menu-item dropdown dropdown-mobile-full">
      <a
        href="#"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        className="menu-link"
      >
        <div className="menu-icon">
          <i className="bi bi-grid-3x3-gap nav-icon" />
        </div>
      </a>
    </div>
    <div className="menu-item dropdown dropdown-mobile-full">
      <a
        href="#"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        className="menu-link"
      >
        <div className="menu-icon">
          <i className="bi bi-bell nav-icon" />
        </div>
        <div className="menu-badge bg-theme" />
      </a>
    </div> */}
    {/* <div className="menu-item dropdown dropdown-mobile-full">
      <a
        href="#"
        data-bs-toggle="dropdown"
        data-bs-display="static"
        className="menu-link"
      >
        <div className="menu-img online">
          <img className="menu-profile-img" src="/images/avatar.jpeg " alt="" />
        </div>
        <div className="menu-text d d-none">ubaid29170@gmail.com</div>
      </a>
      <div className="dropdown-menu dropdown-menu-end me-lg-3 mt-1 w-200px">
        <a
          className="dropdown-item d-flex align-items-center"
          href="profile.html"
        >
          <i className="far fa-user fa-fw fa-lg me-3" /> Profile
        </a>
        <a
          className="dropdown-item d-flex align-items-center"
          href="email_inbox.html"
        >
          <i className="far fa-envelope fa-fw fa-lg me-3" /> Inbox
        </a>
        <a
          className="dropdown-item d-flex align-items-center"
          href="calendar.html"
        >
          <i className="far fa-calendar fa-fw fa-lg me-3" /> Calendar
        </a>
        <a
          className="dropdown-item d-flex align-items-center"
          href="settings.html"
        >
          <i className="fa fa-sliders fa-fw fa-lg me-3" /> Settings
        </a>
        <div className="dropdown-divider" />
        <a
          className="dropdown-item d-flex align-items-center"
          href="page_login.html"
        >
          <i className="fa fa-arrow-right-from-bracket fa-fw fa-lg me-3" />{" "}
          Logout
        </a>
      </div>
    </div> */}
  </div>
  {/* END menu */}
  {/* BEGIN menu-search */}
  <form className="menu-search" method="POST" name="header_search_form">
    <div className="menu-search-container">
      <div className="menu-search-icon">
        <i className="bi bi-search" />
      </div>
      <div className="menu-search-input">
        <input
          type="text"
          className="form-control form-control-lg"
          placeholder="Search menu..."
        />
      </div>
      <div className="menu-search-icon">
        <a
          href="#"
          data-toggle-class="app-header-menu-search-toggled"
          data-toggle-target=".app"
        >
          <i className="bi bi-x-lg" />
        </a>
      </div>
    </div>
  </form>
  {/* END menu-search */}
</div>

  );
};

export default Header;
