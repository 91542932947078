import React, { useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import BaseUrl from '../../BaseUrl'
import toast from 'react-simple-toasts'
import Swal from 'sweetalert2'
import moment from 'moment'

const ExpiredClients = () => {

    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [mysearch, setSearch] = useState('')



    
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }


    const query = useQuery();
    const page = query.get('page');
    const search = query.get('search');




    const onsubmitsearch = (spage, ssearch) => {
        try {

            axios.get(`${BaseUrl.baseUrl}/expiredClients?page=${spage}&search=${ssearch}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }







    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/expiredClients?page=${page?page:1}&search=${search?search:''}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])





    const del = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to delete this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {

            if (result.isConfirmed) {

                try {

                    axios.delete(`${BaseUrl.baseUrl}/delteClient/${id}`).then(res => {
                        if (res.data.status === 'ok') {
                            toast('🎉 Deleted Successfully!')

                            axios.get(`${BaseUrl.baseUrl}/expiredClients?page=${page?page:1}&search=${search?search:''}`).then(res => {
                                if (res.data.status === 'ok') {
                                    setData(res.data.data)
                                }
                                else if (res.data.status === 'fail') {
                                    toast('🚩' + res.data.message)
                                }

                            }).catch(err => {
                                toast('🚨' + err.message)
                            })

                        }
                        else if (res.data.status === 'fail') {
                            toast('🚩' + res.data.message)
                        }

                    }).catch(err => {
                        toast('🚨' + err.message)
                    })


                } catch (err) {
                    toast('🚨 Something went wrong!')
                }
            }


        })
    }



    return (
        <div id="content" className="app-content ms-0">
            <div className="d-flex align-items-center mb-3">
                <div>
                    <ul className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="#">Pages</Link>
                        </li>
                        <li className="breadcrumb-item active">Client</li>
                    </ul>
                    <h1 className="page-header mb-0">Clients</h1>
                </div>
                {/* <div className="ms-auto">
                    <Link to="/addClient" className="btn btn-theme fs-13px fw-semibold ">
                        Add Client
                    </Link>
                </div> */}
            </div>
            <div id="alertContainer" className="col-md-4" />
            <div className="card">
                <ul className="nav nav-tabs nav-tabs-v2 px-3">
                    <li className="nav-item me-sm-2">
                        <Link to="#allTab" className="nav-link active px-2" data-bs-toggle="tab">
                            All
                        </Link>
                    </li>
                </ul>
                <div className="tab-content p-4">
                    <div className="tab-pane fade show active" id="allTab">
                        {/* BEGIN input-group */}
                        <div className="input-group flex-nowrap mb-4">
                            <div className="flex-fill position-relative">
                                <form action="">
                                    <div className="input-group">
                                        <input
                                            type="text"
                                            name="search"
                                            className="form-control px-35px"
                                            placeholder="Filter Client"
                                            onChange={(e) => { setSearch(e.target.value) }}
                                        />
                                        <div
                                            className="input-group-text position-absolute top-0 bottom-0 bg-none border-0 start-0"
                                            style={{ zIndex: 1020 }}
                                        >
                                            <i className="fa fa-search opacity-5" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <button className="btn btn-theme" type="submit" onClick={() => {
                                navigate(`?page=${page}&search=${mysearch}`)
                                onsubmitsearch(page, mysearch)
                            }}>
                                <span className="d-none d-md-inline  btn-theme">Submit</span>
                                <span className="d-inline d-md-none border btn-theme">
                                    <i className="fa fa-check" />
                                </span>
                                &nbsp;
                            </button>
                        </div>
                        {/* END input-group */}
                        {/* BEGIN table */}
                        <div className="table-responsive">

                            <table id="orderTable" className="table table-hover text-nowrap">
                                <thead className="text-center">
                                    <tr>
                                        <th className="border-top-0 pt-0 pb-2">Sr no</th>
                                        <th className="border-top-0 pt-0 pb-2">Name</th>
                                        <th className="border-top-0 pt-0 pb-2">Email</th>
                                        <th className="border-top-0 pt-0 pb-2">Number</th>
                                        <th className="border-top-0 pt-0 pb-2">Agent</th>
                                        <th className="border-top-0 pt-0 pb-2">Plan</th>
                                        <th className="border-top-0 pt-0 pb-2">Business name</th>
                                        <th className="border-top-0 pt-0 pb-2">Status</th>
                                        <th className="border-top-0 pt-0 pb-2">Date</th>
                                        <th className="border-top-0 pt-0 pb-2">Left Days</th>
                                        <th className="border-top-0 pt-0 pb-2">Expire Soon Message</th>
                                        <th className="border-top-0 pt-0 pb-2">Expire Message</th>
                                        <th className="border-top-0 pt-0 pb-2">Update Message</th>
                                        <th className="border-top-0 pt-0 pb-2">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="text-center">
                                    {data?.clients?.map((client,index)=>{
                                        return(<tr>
                                        <td>{index+1}</td>
                                        <td>{client.name}</td>
                                        <td>{client.email}</td>
                                        <td>{client.phone?client.phone:'-'}</td>
                                            <td>{client.client_agent?client.client_agent:'-'}</td>
                                            <td>{client?.plan?.name?client?.plan?.name:'-'}</td>
                                            <td>{client.business_name?client.business_name:'-'}</td>

                                        {client.plan_status === "Active"?<td className="py-1 align-middle">
                                            <span className="badge text-info bg-info bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Active
                                            </span>
                                        </td>:client.plan_status === "Expired"?
                                        <td className="py-1 align-middle">
                                            <span className="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                {client.plan_status}
                                            </span>
                                        </td>:client.plan_status === "Expired_soon"?
                                        <td className="py-1 align-middle">
                                            <span className="badge text-warning bg-warninig bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                {client.plan_status}
                                            </span>
                                        </td>:
                                        <td className="py-1 align-middle">
                                            <span className="badge text-info bg-info bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" /> Inactive
                                            </span>
                                        </td>}



                                        <td>{client.formattedDate}</td>
                                        <td>{client.remainingDays}</td>

                                        {client.soonExpiredMessage === 1?<td className="py-1 align-middle">
                                            <span className="badge text-success bg-success bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Sent
                                            </span>
                                        </td>
                                        :
                                        <td className="py-1 align-middle">
                                            <span className="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Not Sent
                                            </span>
                                        </td>}


                                        {client.expiredMessage === 1?<td className="py-1 align-middle">
                                            <span className="badge text-success bg-success bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Sent
                                            </span>
                                        </td>
                                        :
                                        <td className="py-1 align-middle">
                                            <span className="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Not Sent
                                            </span>
                                        </td>}


                                        {client.updateAccountMessage === 1?<td className="py-1 align-middle">
                                            <span className="badge text-success bg-success bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Sent
                                            </span>
                                        </td>
                                        :
                                        <td className="py-1 align-middle">
                                            <span className="badge text-danger bg-danger bg-opacity-15 px-2 py-6px rounded fs-12px d-inline-flex align-items-center">
                                                <i className="fa fa-circle fs-9px fa-fw me-5px" />
                                                Not Sent
                                            </span>
                                        </td>}
                                        <td>
                                            &nbsp; &nbsp;
                                            {/* Your button with orderId as a data attribute */}
                                            &nbsp; &nbsp;
                                            <div
                                                onClick={()=>{
                                                    navigate(`/editClient`,{state:{id:client.id}})
                                                }}
                                                    className="fs-13px fw-semibold edit-category btn btn-theme"
                                                    data-bs-target="#editModal"
                                                >
                                                    Edit
                                                </div>
                                            &nbsp; &nbsp;
                                            <div
                                            onClick={()=>{del(client?.id)}}
                                                className="btn btn-danger"
                                            >
                                                Delete{" "}
                                            </div>
                                        </td>
                                    </tr>)})}
                                </tbody>
                            </table>
                        </div>
                        {/* END table */}
                        {/* edit model start */}
                        <div
                            className="modal fade"
                            id="editModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Edit Client
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div id="formResponse" />
                                        <form action="/updatePlan" id="createUserForm" method="post">
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Client Name:
                                                </label>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    className="form-control"
                                                    id="edit-client-name"
                                                    placeholder="Client Name"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Client Email:
                                                </label>
                                                <input
                                                    type="text"
                                                    name="Email"
                                                    className="form-control"
                                                    id="edit-client-email"
                                                    placeholder="Client Email"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Client Number:
                                                </label>
                                                <input
                                                    type="text"
                                                    name="number"
                                                    className="form-control"
                                                    id="edit-client-number"
                                                    placeholder="Client Name"
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Client Agent:
                                                </label>
                                                <select
                                                    name="client_agent"
                                                    id="edit-client-agent"
                                                    className="form-select"
                                                >
                                                    <option value="" disabled="">
                                                        Select the Agent
                                                    </option>
                                                    <option value="Afaq Ahmed">Afaq Ahmed </option>
                                                    <option value="Ari Akash">Ari Akash</option>
                                                    <option value="Bilal Tariq">Bilal TAriq</option>
                                                    <option value="Muhammad Hadi">Muhammad Hadi</option>
                                                    <option value="Muhammad Mosa">Muhammad Mosa</option>
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Plan Name:
                                                </label>
                                                <select
                                                    name="plan"
                                                    id="edit-client-plan"
                                                    className="form-select"
                                                >
                                                    <option value="" disabled="">
                                                        Select the plan
                                                    </option>
                                                    &lt;% plans.forEach(plan =&gt; {"{"} %&gt;
                                                    <option value="">&lt;%= plan.planName %&gt;</option>
                                                    &lt;% {"}"}) %&gt;
                                                </select>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="recipient-name" className="col-form-label">
                                                    Plan Name:
                                                </label>
                                                <select
                                                    name="plan"
                                                    id="edit-client-status"
                                                    className="form-select"
                                                >
                                                    <option value="" disabled="">
                                                        Select the Status
                                                    </option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                    <option value="Expired">Expired</option>
                                                </select>
                                            </div>
                                            <div className="first d-flex justify-content-between"></div>
                                        </form>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-bs-dismiss="modal"
                                        >
                                            Close
                                        </button>
                                        <button type="submit" className="btn btn-primary">
                                            Update Setting
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* edit model end */}
                        {/* confirm delete  */}
                        <div id="custom-modal" className="overlay">
                            <div className="modal">
                                <span className="close-btn" onclick="closeModal()">
                                    ×
                                </span>
                                <p>Are you sure you want to delete this?</p>
                                <button onclick="confirmDelete()">Yes, delete</button>
                            </div>
                        </div>
                        {/* confirm delete  */}



                        <div className="d-lg-flex align-items-center">
                            <div className="me-lg-auto text-md-left text-center mt-2 mt-lg-0 mb-2 mb-lg-0">
                                Showing {data?.page} to {data?.limit} of {data?.count} entries
                            </div>
                            <ul className="pagination mb-0 justify-content-center flex-wrap">
                                {data?.page > 1 ? (
                                    <li className="page-item mb-sm-0 mb-1">
                                        <div className="page-link" style={{ cursor: 'pointer' }} onClick={() => {
                                            navigate(`?page=${data?.page - 1}&search=${search?search:''}`)
                                            onsubmitsearch(data?.page - 1, search?search:'')
                                        }
                                        }>
                                            Previous
                                        </div>
                                    </li>
                                ) : (
                                    <li className="page-item mb-sm-0 mb-1 disabled">
                                        <Link className="page-link">Previous</Link>
                                    </li>
                                )}

                                {[...Array(data?.totalPages).keys()].map(i => (
                                    <li key={i + 1} className={`page-item mb-sm-0 mb-1 ${i + 1 === data?.page ? 'active' : ''}`}>
                                        <div className="page-link" style={{ cursor: 'pointer' }} onClick={() => {
                                            navigate(`?page=${i + 1}&search=${search?search:''}`)
                                            onsubmitsearch(i + 1, search?search:'')
                                        }
                                        }>
                                            {i + 1}
                                        </div>
                                    </li>
                                ))}

                                {data?.page < data?.totalPages ? (
                                    <li className="page-item mb-sm-0 mb-1">
                                        <div className="page-link" style={{ cursor: 'pointer' }} onClick={() => {
                                            navigate(`?page=${data?.page + 1}&search=${search?search:''}`)
                                            onsubmitsearch(data?.page + 1, search?search:'')
                                        }}>
                                            Next
                                        </div>
                                    </li>
                                ) : (
                                    <li className="page-item mb-sm-0 mb-1 disabled">
                                        <Link className="page-link">Next</Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
}

export default ExpiredClients