import React, { useContext, useEffect, useState } from 'react'
import Header from '../../Components/Header'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import MapContext from '../../Components/MapContext';
import toast from 'react-simple-toasts';
import axios from 'axios';
import BaseUrl from '../../BaseUrl';
import moment from 'moment';
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';


const ViewQuotation = () => {



    const navigate = useNavigate()
    const location = useLocation()
    const { id } = location.state

    const targetRef = useRef();






    const [data, setData] = useState({})


    useEffect(() => {
        try {

            axios.get(`${BaseUrl.baseUrl}/quotation/get/${id}`).then(res => {
                if (res.data.status === 'ok') {
                    setData(res.data.data)
                }
                else if (res.data.status === 'fail') {
                    toast('🚩' + res.data.message)
                }

            }).catch(err => {
                toast('🚨' + err.message)
            })


        } catch (err) {
            toast('🚨 Something went wrong!')
        }

    }, [])








    return (
        <>

            <button href="#" className="btn btn-default m-3 align-self-end bg-lime" onClick={() => generatePDF(targetRef, { filename: `oncloudapis${data?.id}.pdf` })}>
                <i className="fa fa-download mr5" /> Downnload
            </button>

            <div className='w-100 h-100'>



                <div className=" bootdey bg-white   my-2 px-0  rounded-3" style={{ margin: '3rem 17rem' }} ref={targetRef} >

                    <div className='d-flex align-items-end' style={{ backgroundImage: `url(${require('../../Assests/head1.png')})`, backgroundSize: '100%' }}>
                        {/* backgroundColor: "#81F32D", */}
                        <div className='p-4 px-5' style={{ width: '55%', height: '90px', clipPath: 'polygon(0% 0%, 85% 0%, 100% 100%, 0% 100%)', zIndex: 9999 }}>
                            <h1 className='fw-bolder text-white'>INVOICE</h1>
                        </div>
                        <div className='p-2 d-flex justify-content-center align-items-end' style={{
                            width: '34%', height: '65px', clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 10% 100%)', position: 'absolute',
                            right: '223px'
                        }}>
                            <img
                                width={130}
                                src={require('../../Assests/logoi.png')}
                                alt="Invoice logo"
                            />

                        </div>
                    </div>

                    <div className="row invoice row-printable  ">
                        <div className="col-md-12">
                            {/* col-lg-12 start here */}
                            <div className="panel panel-default plain" id="dash_0">
                                {/* Start .panel */}
                                <div className="panel-body p30">
                                    <div className="row w-100 m-0">
                                        <div className="row w-100 m-0" style={{backgroundImage:`url(${require('../../Assests/head2.png')})`,backgroundSize:'95% 100%',objectFit:'contain',backgroundRepeat:'no-repeat'}}>
                                            {/* Start .row */}
                                            <div className="col-lg-6">
                                                {/* col-lg-6 start here */}
                                                <div className="invoice-logo p-4 px-5" style={{ }}>
                                                    <div className="invoice-to mt25">
                                                        <ul className="list-unstyled">
                                                            <li>
                                                                <strong>Invoiced To</strong>
                                                            </li>
                                                            <li>{data?.custName}</li>
                                                            <li>{data?.custEmail}</li>
                                                            <li>{data?.custPhone}</li>
                                                            <li>{data?.custAddress}</li>
                                                        </ul>
                                                    </div>


                                                    <div className="well mt-4">
                                                        <ul className="list-unstyled mb0">
                                                            <li>
                                                                <strong>Invoice</strong> #{data?.id}
                                                            </li>
                                                            <li>
                                                                <strong>Invoice Date:</strong> {moment(data?.createdAt).format('dddd, MMMM DD, YYYY')}
                                                            </li>
                                                            <li>
                                                                <strong>Due Date:</strong> {moment(data?.dueDate).format('dddd, MMMM DD, YYYY')}
                                                            </li>
                                                            <li>
                                                                <strong>Status:</strong>{" "}
                                                                <span className={`label label-danger px-3 rounded-2 text-white ${data?.status === 'Paid' ? 'bg-info' : 'bg-danger'}`}>{data?.status}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* col-lg-6 end here */}
                                            <div className="col-lg-6">
                                                {/* col-lg-6 start here */}
                                                <div className="invoice-from p-4 ">
                                                    <ul className="list-unstyled text-right">
                                                        <li>{data?.companyName}</li>
                                                        <li>{data?.companyEmail}</li>
                                                        <li>{data?.companyPhone}</li>
                                                        <li>{data?.companyAddress}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* col-lg-6 end here */}
                                        <div className="col-lg-12 mt-4 px-5">
                                            {/* col-lg-12 start here */}

                                            <div className="invoice-items">
                                                <div
                                                    className="table-responsive pb-5"
                                                    style={{ overflow: "hidden", outline: "none" }}
                                                    tabIndex={0}
                                                >
                                                    <table className="table table-striped table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th className="per70 ">Description</th>
                                                                <th className="per5 text-center">Qty</th>
                                                                <th className="per25 text-center">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {data?.product?.map(i=>{
                                                                return(<>
                                                                <tr>
                                                                <td className='p-3' >
                                                                    {i?.name}
                                                                </td>
                                                                <td className="text-center p-3">{i?.quant}</td>
                                                                <td className="text-center p-3" style={{ padding: '1.5rem' }}>${i?.price} USD</td>
                                                            </tr>
                                                            
                                                            </>)
                                                            })
                                                            }
                                                            
                                                            
                                                        </tbody>

                                                    </table>
                                                </div>

                                                <div className='d-flex justify-content-end'>
                                                    <div className='d-flex justify-content-end gap-3 flex-column'>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <th colSpan={2} className="text-right">
                                                            Sub Total:
                                                        </th>
                                                        <th className="text-center">${data?.subTotal} USD</th>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <th colSpan={2} className="text-right">
                                                            Tax:
                                                        </th>
                                                        <th className="text-center">${data?.tax} USD</th>
                                                    </div>
                                                    <div className='d-flex justify-content-between gap-5'>
                                                        <th colSpan={2} className="text-right">
                                                            Total:
                                                        </th>
                                                        <th className="text-center">${data?.total} USD</th>
                                                    </div>
                                                    </div>
                                                </div>

                                                
                                            </div>
                                            <div className="invoice-footer my-3">
                                                <p className="text-center">
                                                    Generated on {moment().format('dddd, MMMM DD, YYYY')}{" "}
                                                </p>
                                            </div>




                                        </div>
                                        {/* col-lg-12 end here */}
                                    </div>
                                    {/* End .row */}
                                </div>

                                {/* <div className='d-flex align-items-end mt-3'>
                                    <div className='bg-lime p-4 px-5' style={{ width: '55%', height: '45px', backgroundColor: "#81F32D" }}>
                                    </div>
                                    <div className='p-2 d-flex justify-content-center align-items-end' style={{ width: '45%', height: '65px', backgroundColor: 'black' }}>


                                    </div>
                                </div> */}
                            </div>
                            {/* End .panel */}
                        </div>
                        {/* col-lg-12 end here */}
                    </div>
                </div>
            </div>
        </>

    )
}

export default ViewQuotation